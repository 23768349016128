import topic from '../topic'
export const apprAPI = {
    getAllApprs: topic.apprTopic + '/get_all_apprs',
    getApprById: topic.apprTopic + '/get_appr_by_id',
    editApprNode: topic.apprTopic + '/edit_appr_node',
    addAppr: topic.apprTopic + '/add_appr',
    editAppr: topic.apprTopic + '/edit_appr',
    getApprNodesByApprId: topic.apprTopic + '/get_appr_nodes_by_appr_id',
    destroyApprNodeById: topic.apprTopic + '/destroy_appr_node_by_id',
    getApprInsts: topic.apprTopic + '/get_appr_insts',
    getApprInstNextNode: topic.apprTopic + '/get_appr_inst_next_node',
    Approve: topic.apprTopic + '/approve',
    makeEffective: topic.apprTopic + '/make_effective',
    getIApprove: topic.apprTopic + '/get_i_approve',
    getApproveMe: topic.apprTopic + '/get_approve_me',
    makeIneffective: topic.apprTopic + '/make_ineffective',
}