import topic from '../topic';
import { getNoCatch } from '@api/request';
export const matrAPI = {
  getPucas: topic.purcTopic + '/get_pucas',
  addPuca: topic.purcTopic + '/add_puca',
  editPuca: topic.purcTopic + '/edit_puca',
  getPucaById: topic.purcTopic + '/get_puca_by_id',
  getPucaByIds: topic.purcTopic + '/get_puca_by_ids',
  getAllPucaMtrb: topic.purcTopic + '/get_all_puca_mtrb',
  addDent: topic.dentTopic + '/add_dent',
  puca_invalid_by_ids: topic.purcTopic + '/puca_invalid_by_ids',
  import_excel: topic.purcTopic + '/add_puca_by_excel',
  editPucaMtrbCnum: topic.purcTopic + '/edit_puca_mtrb_cnum',
  pucaInvalidByIds: topic.purcTopic + '/puca_invalid_by_ids',
  addPucaMtrb: topic.purcTopic + '/add_puca_mtrb',
  editPucaMtrbRemark: topic.purcTopic + '/edit_puca_mtrb_remark',
  addDentByPucaMtrbIds: topic.dentTopic + '/add_dent_by_puca_mtrb_ids',
  editPucaChanStatus: topic.purcTopic + '/edit_puca_chan_status', // 修改变更内容已阅读状态
  getPucaByChange: topic.purcTopic + '/get_puca_by_change', // 订单变更提醒
  countPucaByChange: topic.purcTopic + '/count_puca_by_change', // 订单变更提醒数量
  getPucaByScan: topic.purcTopic + '/get_puca_by_scan', // 补货单新增提醒
  countPucaByScan: topic.purcTopic + '/count_puca_by_scan', // 补货单新增提醒数量
  editPucaSacn: topic.purcTopic + '/edit_puca_sacn', // 补货单新增提醒数量确认
  addPucaChan: topic.purcTopic + '/add_puca_chan', // 添加记录
  syncPucaByErp: topic.purcTopic + '/sync_puca_by_erp', // 同步erp
  getDentByPucaId: topic.purcTopic + '/get_dent_by_puca_id', // 获取关联送货单
  exportPucaPdfByModrId: topic.purcTopic + '/export_puca_pdf_by_modr_id', // 打印pdf
  editPucaMtrbIsUpdate0: param => getNoCatch(`${topic.purcTopic}/editPucaMtrbIsUpdate0`, param)
};
