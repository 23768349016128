import topic from '../topic';
import { getNoCatch } from '@api/request';
export const shweAPI = {
  getShwes: topic.shweTopic + '/get_shwes',
  getShweById: topic.shweTopic + '/get_shwe_by_id',
  addShwe: topic.shweTopic + '/add_shwe',
  getAbnormalShwe: topic.shweTopic + '/get_abnormal_shwe',
  countAbnormalShwe: topic.shweTopic + '/count_abnormal_shwe',
  updateScanById: topic.shweTopic + '/update_scan_by_id',
  getYesterdayShwe: topic.shweTopic + '/get_yesterday_shwe',
  getSgetShwesReplaceListhwes: params => getNoCatch(`${topic.shweTopic}/getSgetShwesReplaceListhwes`, params),
  getOperationdetails: params => getNoCatch(`${topic.shweTopic}/getOperationdetails`, params),
  getSharpweaDetailsRequst: params => getNoCatch(`${topic.shweTopic}/getSharpweaDetailsRequst`, params)
};
