<template>
  <div class="vg_wrapper">
    <el-row :gutter="24">
      <el-col :md="9">
        <!-- 需审批 -->
        <board-left></board-left>  
      </el-col>
      <el-col :md="9">
        <!-- 被审批 -->
        <board-right></board-right>
      </el-col>
      <el-col :md="6">
        <!-- 推送提醒 -->
        <board-push></board-push>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get} from "@api/request";
import {userAPI} from "@api/modules/user";
import boardLeft from "@/views/component/boardCommon/boardLeft";
import boardRight from "@/views/component/boardCommon/boardRight";
import boardPush from "@/views/component/boardCommon/boardPush";
export default {
  name: 'Home',
  components: {
    boardPush,
    boardLeft,
    boardRight
  },
  data(){
    return{

    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.isLogin()
    },
    isLogin(){
      get(userAPI.logUserId)
      .then(res=>{
        // if(res.data.data){
        //   console.log('登录状态。。。',res.data.data)
        // } else {
        //   this.jump(`/login`)
        // }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.vd_card_button {
  padding: 2px 0px;
}
</style>