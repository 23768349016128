import topic from '../topic'
export const dentAPI = {
    getDents:topic.dentTopic + '/get_dents',
    addDent:topic.dentTopic + '/add_dent',
    getDentById:topic.dentTopic + '/get_dent_by_id',
    getDentByIds:topic.dentTopic + '/get_dent_by_ids',
    editDent:topic.dentTopic + '/edit_dent',
    editDents:topic.dentTopic + '/edit_dent_by_dents',
    deleteDentByIds: topic.dentTopic + '/delete_dent_by_ids',
    createShipMarkQrcode: topic.dentTopic + '/create_ship_mark_qrcode',
    get_dent_mtrb_by_podr_modr_no: topic.dentTopic + '/get_dent_mtrb_by_podr_modr_no',
    add_dent_by_puca_ids: topic.dentTopic + '/add_dent_by_puca_ids',
    getDentByScan: topic.dentTopic + '/get_dent_by_scan',//工厂发货提醒
    countDentByScan: topic.dentTopic + '/count_dent_by_scan',//工厂发货提醒数量
    editDentSacn: topic.dentTopic + '/edit_dent_sacn',//工厂发货提醒数量确认
    cancalDentInfo: topic.dentTopic + '/cancal_dent_info',//恢复数据
    cancalDentInfoByIds: topic.dentTopic + '/cancal_dent_info_by_ids',//多选发货恢复数据
    getPodrNosByModrNo: topic.dentTopic + '/get_podr_nos_by_modr_no',//多选发货恢复数据
    getDentDetailByDentId: topic.dentTopic + '/getDentDetailByDentId',// 获取所有发货明细信息
    getDentDetailByNumAndDentId: topic.dentTopic + '/getDentDetailByNumAndDentId',// 获取详细发货明细信息
    getDentNosByDentIds: topic.dentTopic + '/getDentNosByDentIds',// 合并发货打印详情

}