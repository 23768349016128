<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently"
          >推送提醒 <span class="el-icon-refresh" :class="{ vg_rotate: rotateFlag }" size="mini" @click="loadingChange" circle></span
        ></span>
      </div>
      <div class="card_body">
        <div v-if="pushList.length > 0">
          <el-row v-for="(item, index) in pushList" :key="index">
            <el-badge :value="item.icon" class="vd_item">
              <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)"
                >{{ item.perm_name }}
              </el-link>
            </el-badge>
          </el-row>
        </div>
        <div class="el-icon-warning-outline notVal" v-else>暂无推送</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { macpAPI } from '@api/modules/macp';
import { shweAPI } from '@api/modules/shwe';
import { matrAPI } from '@api/modules/matr';
import { dentAPI } from '@api/modules/dent';
import { fcreAPI } from '@api/modules/fcre';
import { popuAPI } from '../../../api/modules/popu';
import { recordAPI } from '@api/modules/record';

export default {
  name: 'boardPush',
  data() {
    return {
      pushList: [
        {
          perm_url: '/macp_push_list_9001',
          perm_name: '机器未检验',
          icon: 0
        },
        {
          perm_url: '/macp_push_list_9002',
          perm_name: '机器异常',
          icon: 0
        },
        {
          perm_url: '/shwe_push_list_9001',
          perm_name: '利器数量异常',
          icon: 0
        },
        {
          perm_url: '/shwe_push_list_9002',
          perm_name: '未回收数量',
          icon: 0
        },
        {
          perm_url: '/puca_push_list_9001',
          perm_name: '材料订单变更提醒',
          icon: 0
        },
        {
          perm_url: '/puca_push_list_9002',
          perm_name: '补货单新增提醒',
          icon: 0
        },
        {
          perm_url: '/dent_push_list_9001',
          perm_name: '工厂发货提醒',
          icon: 0
        },
        {
          perm_url: '/fcre_push_list_9001',
          perm_name: '工厂收货提醒',
          icon: 0
        },
        {
          perm_url: '/popu_push_list_9001',
          perm_name: '成品订单变更提醒',
          icon: 0
        },
        {
          perm_url: '/shwe_push_list_9003',
          perm_name: '断针记录未上传',
          icon: 0
        }
      ],
      rotateFlag: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDoneMacp();
      this.getAbnormalMacp();
      this.getAbnormalShwe();
      this.getYesterdayShwe();
      this.countPucaByChange();
      this.countPucaByScan();
      this.countDentByScan();
      this.countFcreByScan();
      this.countPropucaByChange();
      this.getRecordRemindTotalByStatus();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    // 获取机器未检验
    getDoneMacp() {
      get(macpAPI.countNotDoneMacp).then(res => {
        if (res.data.code === 0) {
          this.pushList[0].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取机器未检验
    getAbnormalMacp() {
      get(macpAPI.countAbnormalMacp).then(res => {
        if (res.data.code === 0) {
          this.pushList[1].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取利器数量异常
    getAbnormalShwe() {
      get(shweAPI.countAbnormalShwe).then(res => {
        if (res.data.code === 0) {
          this.pushList[2].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取未回收数量
    getYesterdayShwe() {
      get(shweAPI.getYesterdayShwe).then(res => {
        if (res.data.code === 0) {
          this.pushList[3].icon = res.data.data.list.length;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 订单变更提醒数量
    countPucaByChange() {
      get(matrAPI.countPucaByChange).then(res => {
        if (res.data.code === 0) {
          this.pushList[4].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 补货单新增提醒数量
    countPucaByScan() {
      get(matrAPI.countPucaByScan).then(res => {
        if (res.data.code === 0) {
          this.pushList[5].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 工厂发货提醒数量
    countDentByScan() {
      get(dentAPI.countDentByScan).then(res => {
        if (res.data.code === 0) {
          this.pushList[6].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 工厂收货提醒数量
    countFcreByScan() {
      get(fcreAPI.countFcreByScan).then(res => {
        if (res.data.code === 0) {
          this.pushList[7].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 成品订单提醒数量
    countPropucaByChange() {
      get(popuAPI.countPropucaByChange).then(res => {
        if (res.data.code === 0) {
          this.pushList[8].icon = res.data.data.total;
        } else if (res.data.code === 3) {
          console.log('未登录');
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    getRecordRemindTotalByStatus() {
      recordAPI.getRecordRemindTotalByStatus().then(({ data }) => {
        this.pushList[9].icon = data.data;
      });
    },
    goPermList(val) {
      this.jump(val);
    },
    loadingChange() {
      this.rotateFlag = true;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_item {
  margin-top: 10px;
  margin-right: 40px;
}

.card_body {
  padding-right: 10px;
  overflow: auto;
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}

.vd_borb {
  margin-bottom: 20px;
}

.vd_borb :hover {
  box-shadow: 2px 2px 3px #cccccc;
}

.el-icon-refresh {
  cursor: pointer;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
}

.vd_ml12 {
  margin-left: 12px;
}

.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
    margin-top: 5px;
  }

  span {
    margin: 0 10px;
    margin-top: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      color: blue;
    }
  }
}
</style>
