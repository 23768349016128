import topic from '../topic'
export const popuAPI = {
    getPopus: topic.popuTopic + '/getPopus',
    getPopuById: topic.popuTopic + '/getPopuById',
    editPopu: topic.popuTopic + '/editPopuById',
    editPopuRemark: topic.popuTopic + '/editPopuRemark',
    countPropucaByChange: topic.popuTopic + '/count_propuca_by_change',
    getPropucaByChange: topic.popuTopic + '/get_propuca_by_change',
    getPucaByPopuId: topic.popuTopic + '/getPucaByPopuId',  // 获取关联送货单
    editPopuChanStatus: topic.popuTopic + '/editPopuChanStatus',  // 获取关联送货单
}